<script setup lang="ts"></script>

<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_4)">
      <circle cx="14" cy="14" r="14" fill="currentColor" />
      <path d="M14.3333 6L16.3006 12.0545H22.6667L17.5164 15.7964L19.4836 21.8509L14.3333 18.1091L9.18305 21.8509L11.1503 15.7964L6 12.0545H12.3661L14.3333 6Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1_4">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
