<script setup lang="ts">
import { type Modello } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";

defineProps<{
  content: Modello;
}>();

const df = new DateFormatter("it-IT", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});
</script>

<template>
  <FrontContentCardsBaseContent v-bind="$props" :content="content">
    <template #preview>
      <IconsDocument class="flex-none text-primary w-12 h-12 mb-2"></IconsDocument>
    </template>
    <template #info>
      <p class="info-number mt-2">Cod. {{ content.code }}</p>
    </template>
    <template #center>
      <div class="grid items-start place-items-start gap-3">
        <span class="chip chip-green" v-if="content.last_update && content.is_updated">Aggiornato il {{ df.format(content.last_update) }}</span>
        <FrontModelDownloadButton :content="content" />
      </div>
    </template>
  </FrontContentCardsBaseContent>
</template>
