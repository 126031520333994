<script setup lang="ts">
import type { Area } from "@gasparigit/omnia-sdk";
import { AREAS_MAP } from "~/lib/const";
import { link } from "~/lib/utils/contents";
import { processError } from "~/lib/utils/errors";

const omniaSdk = useOmniaSdk();
const emit = defineEmits(["save"]);

const props = defineProps<{
  areas: Area[];
  active?: Area[];
  showSaved?: boolean;
}>();

async function saveArea(area: Area) {
  try {
    const saved = await omniaSdk.v1.areas.save(area.id);
    area.is_saved = saved.is_saved;

    emit("save");
  } catch (error) {
    processError(error);
  }
}

function isActive(area: Area) {
  if (props.active === undefined) return true;

  return props.active.find((a) => a.id === area.id) ? true : false;
}
</script>

<template>
  <div class="flex flex-wrap items-center justify-center gap-10 mt-10">
    <NuxtLink :href="isActive(area) ? link({ area }) : ''" v-for="area in areas" :class="['flex flex-col items-center gap-1', isActive(area) ? 'cursor-pointer' : '']">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <div class="w-14 h-14 relative">
              <component :class="[!isActive(area) ? 'opacity-40' : `text-${AREAS_MAP[area.id].color}`]" v-if="area.id in AREAS_MAP" :is="AREAS_MAP[area.id].icon" :active="isActive(area)" />
              <IconsStar
                @click.prevent="saveArea(area)"
                v-if="showSaved"
                class="absolute w-7 h-7 -right-2 -top-2 cursor-pointer transition-all duration-300 ease-in-out active:scale-75"
                :class="area.is_saved ? 'text-[#F6B100]' : 'text-gray-400 hover:text-[#F6B100]'"
              />
            </div>
            <span>{{ AREAS_MAP[area.id]?.title || area.title }}</span>
          </TooltipTrigger>
          <TooltipContent class="max-w-screen-sm">
            <p v-if="!isActive(area)">Non hai acquistato quest'area. Contatta la redazione per maggiori informazioni</p>
            <p v-else>{{ area.abstract || area.title }}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </NuxtLink>
  </div>
</template>
