<script setup lang="ts">
import { type FrontContent } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import { AREAS_MAP } from "~/lib/const";
import { getContentType, link } from "~/lib/utils/contents";
import type { CreateLinkConfig } from "~/utils/types";

defineProps<{
  content: FrontContent;
  infoLabel?: string;
  showAbstract?: boolean;
  linkConfig?: CreateLinkConfig;
}>();

const df = new DateFormatter("it-IT", {
  dateStyle: "long",
});
</script>

<template>
  <div class="flex flex-col bg-white shadow-lg p-5">
    <slot name="preview"></slot>

    <div class="mb-5">
      <div class="info-label">{{ infoLabel || getContentType(content) }}</div>
      <slot name="info"></slot>
      <div v-if="!$slots.info">
        <p class="info-number mt-2">{{ df.format(content.published_at || content.created_at) }}</p>
      </div>
    </div>

    <slot name="header"></slot>

    <h3 class="font-semibold text-xl mb-3">
      <NuxtLink v-if="linkConfig" :to="link({ ...linkConfig, content })" :title="content.title" class="hover:text-primary-600 transition-colors">
        {{ content.title }}
      </NuxtLink>
      <span v-else>{{ content.title }}</span>
    </h3>

    <p v-if="showAbstract" class="text-themeGray my-2 line-clamp-6">
      {{ content.abstract }}
    </p>

    <slot name="center"></slot>

    <div class="flex flex-wrap gap-x-2 font-semibold text-base pt-4 mt-auto">
      <span v-for="area in content.areas" :class="['text-' + AREAS_MAP[area.id].color + '-foreground']">{{ area.title }}</span>
    </div>

    <slot name="footer"></slot>
  </div>
</template>
